<template>
	<div class="leftTab">
		<div class="info">
			<div class="left">
				<img :src="userInfo.avatar" alt="">
			</div>
			<div class="right">
				<div class="">
					{{userInfo.nick_name}}
				</div>
				<div class="">
					{{i18n('230')}}：<span>₱ {{userInfo.balance || 0}}</span>
				</div>
			</div>
		</div>
		<div class="list">
			<!-- <div class="item" @click="typeChange(1,'/invitation')">
				<div class="left">
					<img src="@/assets/userInfo/01Ac.png" alt="" v-if="type==1">
					<img src="@/assets/userInfo/01.png" alt="" v-else>
				</div>
				<div :class="type==1?'rignt rightAc':'rignt'">
					{{i18n('26')}}
				</div>
			</div> -->
			<div class="item" @click="typeChange(2,'/myOrder')">
				<div class="left">
					<img src="@/assets/userInfo/02Ac.png" alt="" v-if="type==2">
					<img src="@/assets/userInfo/02.png" alt="" v-else>
				</div>
				<div :class="type==2?'rignt rightAc':'rignt'">
					{{i18n('33')}}
				</div>
			</div>
			<div class="item" @click="typeChange(3,'/recharge')">
				<div class="left">
					<img src="@/assets/userInfo/03Ac.png" alt="" v-if="type==3">
					<img src="@/assets/userInfo/03.png" alt="" v-else>
				</div>
				<div :class="type==3?'rignt rightAc':'rignt'">
					{{i18n('25')}}
				</div>
			</div>
			<div class="item" @click="typeChange(4,'/coupon')">
				<div class="left">
					<img src="@/assets/userInfo/04Ac.png" alt="" v-if="type==4">
					<img src="@/assets/userInfo/04.png" alt="" v-else>
				</div>
				<div :class="type==4?'rignt rightAc':'rignt'">
					{{i18n('22')}}
				</div>
			</div>
		</div>
		<div class="list">
			<div class="item" @click="typeChange(5,'/message')">
				<div class="left">
					<img src="@/assets/userInfo/05Ac.png" alt="" v-if="type==5">
					<img src="@/assets/userInfo/05.png" alt="" v-else>
				</div>
				<div :class="type==5?'rignt rightAc':'rignt'">
					{{i18n('231')}}
				</div>
			</div>
			<div class="item" @click="typeChange(6,'/address')">
				<div class="left">
					<img src="@/assets/userInfo/06Ac.png" alt="" v-if="type==6">
					<img src="@/assets/userInfo/06.png" alt="" v-else>
				</div>
				<div :class="type==6?'rignt rightAc':'rignt'">
					{{i18n('23')}}
				</div>
			</div>
			<div class="item" @click="typeChange(7,'/info')">
				<div class="left">
					<img src="@/assets/userInfo/07Ac.png" alt="" v-if="type==7">
					<img src="@/assets/userInfo/07.png" alt="" v-else>
				</div>
				<div :class="type==7?'rignt rightAc':'rignt'">
					{{i18n('21')}}
				</div>
			</div>
		</div>
		<div class="list">
			<div class="item" @click="typeChange(8,'/QandA')">
				<div class="left">
					<img src="@/assets/userInfo/08Ac.png" alt="" v-if="type==8">
					<img src="@/assets/userInfo/08.png" alt="" v-else>
				</div>
				<div :class="type==8?'rignt rightAc':'rignt'">
					{{i18n('24')}}
				</div>
			</div>
			<div class="item" @click="typeChange(9,'/customer')">
				<div class="left">
					<img src="@/assets/userInfo/09Ac.png" alt="" v-if="type==9">
					<img src="@/assets/userInfo/09.png" alt="" v-else>
				</div>
				<div :class="type==9?'rignt rightAc':'rignt'">
					{{i18n('28')}}
				</div>
			</div>
			<!-- <div class="item">
				<div class="left">
					<img src="@/assets/userInfo/10Ac.png" alt="" v-if="type==10">
					<img src="@/assets/userInfo/10.png" alt="" v-else>
				</div>
				<div :class="type==10?'rignt rightAc':'rignt'">
					设置
				</div>
			</div> -->
		</div>
		<div class="list" style="border: none;">
			<div class="item" @click="signOutOpen">
				<div class="left">
					<img src="@/assets/userInfo/11Ac.png" alt="" v-if="type==11">
					<img src="@/assets/userInfo/11.png" alt="" v-else>
				</div>
				<div :class="type==11?'rignt rightAc':'rignt'">
					{{i18n('40')}}
				</div>
			</div>
		</div>
		<signOut ref="signOut"></signOut>
	</div>
</template>

<script>
	import {
		getBalance
	} from "@/api/user.js"
	
	import signOut from "@/components/topBot/signOut.vue"
	export default {
		name: "leftTab",
		components: {
			signOut
		},
		data() {
			return {
				balanceNumber: 0,
			}
		},
		methods: {
			typeChange(type, url) {
				this.$store.commit("user/setUserCenterType", type)
				this.$router.push(url)
			},
			signOutOpen(){
				this.$refs.signOut.open()
			},
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			},
			type() {
				return this.$store.state.user.userCenterType
			}
		},
		async created() {
			let {
				balance
			} = await getBalance()
			this.balanceNumber = balance
			this.$store.commit('user/setUserInfo',{balance:this.balanceNumber})
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.list {
		padding: 10px 0;
		border-bottom: 1px solid rgba(241, 241, 241, 1);
	}

	.list .item {
		padding-left: 25px;
		height: 36px;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-bottom: 4px;
	}

	.list .item .left {
		width: 44px;
		margin-right: 6px;
	}

	.list .item .left img {
		display: block;
		width: 16px;
		height: 16px;
		margin: 0 auto;
	}

	.list .item .right {
		font-family: .AppleSystemUIFont;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
	}

	.list .item .rightAc {
		color: #56AB7B;
	}

	.info {
		padding: 18px 0 18px 25px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(241, 241, 241, 1);
	}

	.info .left {
		margin-right: 6px;
	}

	.info .left img {
		width: 44px;
		height: 44px;
		display: block;
		border-radius: 50%;
	}

	.info .right div:nth-child(1) {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		font-weight: 500;
	}

	.info .right div:nth-child(2) {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 20px;
		font-weight: 400;
	}

	.info .right div:nth-child(2) span {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #F23725;
		line-height: 20px;
		font-weight: 400;
	}

	.leftTab {
		width: 220px;
		height: 100%;
		background: #FFFFFF;
		overflow-y: scroll;
	}
</style>
