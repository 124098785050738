<template>
	<div class="userCenter">
		<div class="left">
			<leftTab></leftTab>
		</div>
		<div class="right">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import leftTab from "./components/leftTab.vue"
	export default {
		name: "userCenter",
		components:{
			leftTab
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		},
		computed:{
			userInfo(){
				return this.$store.state.user.userInfo
			}
		},
		created() {
			
		},
		mounted() {
			
		}
	};
</script>

<style scoped>
	.userCenter{
		display: flex;
		width: 100%;
		height: 100%;
	}
	.left{
		height: 100%;
	}
	.right{
		flex: 1;
		height: 100%;
		padding: 0 24px;
		background: #F5F5F5;
		box-sizing: border-box;
	}
</style>